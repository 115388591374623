@import '~antd/dist/antd.css';
@import '~highlight.js/styles/github.css';


.content-wrapper {
    background: #fff;
}

.content-wrapper {
    padding: 24px;
}

.content-wrapper .ant-page-header {
    margin: -24px -24px 0;
}

.content-wrapper .ant-table-content {
    margin: 0 -24px;
}

.content-wrapper .ant-pagination {
    margin-bottom: 0;
}

.ant-table-cell:first-child {
    padding-left: 24px !important;
}

.ant-table-cell:last-child {
    padding-right: 24px !important;
}

.ant-pagination-options-size-changer.ant-select {
    margin-right: 0;
}